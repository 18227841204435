import Prismic from 'prismic-javascript';
// -- Prismic API endpoint
const apiEndpoint = 'https://floracalfarmsstaging.prismic.io/api/v2';
// -- Access Token if the repository is not public
const accessToken =
  'MC5ZaC1aa2hFQUFDZ0FBc3FV.77-977-9RxQS77-9BTE4Cgnvv707O--_ve-_vX7vv73vv70V77-9OUTvv71E77-977-977-977-9MO-_vS8';
// -- Prismic Client
const PrismicClient = Prismic.client(apiEndpoint, { accessToken });

export default PrismicClient;
