import React from 'react';
import { get } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import styled from 'styled-components';

const StlWrapper = styled.div`
  padding: 40px;
  max-width: 900px;
  margin: auto;
  h1,
  h2 {
    font-family: 'Gotham Book', sans-serif;
    text-transform: uppercase;
  }
  @media only screen and (min-width: 900px) {
    padding: 150px 40px;
  }
`;

const HeadlineCopyHero = props => {
  return (
    <section className="headline-copy-hero bord-bott-gray">
      <StlWrapper className="text-center">
        <PrismicRichText render={get(props, 'primary.copy')} />
      </StlWrapper>
    </section>
  );
};

export default HeadlineCopyHero;
