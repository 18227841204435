import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';

const RenderShopButton = ({ color }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <li>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="primary"
        size="small"
        disableElevation
        onMouseEnter={handleClick}
        style={{ fontSize: '16px', fontFamily: 'Gotham Book' }}>
        Shop
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <Link to="/shop">Buy Online</Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/store-locator">Store Locator</Link>
        </MenuItem>
      </Menu>
    </li>
  );
};

export default RenderShopButton;
