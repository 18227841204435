import { createTheme } from '@material-ui/core/styles';
const themeObj = {
  palette: {
    primary: {
      main: '#ffffff'
    },
    secondary: {
      main: '#000000'
    },
    error: {
      main: '#ad0000'
    }
  },
  typography: {
    fontFamily: 'Gotham Medium, sans-serif'
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: '8px'
      }
    },
    MuiCollapse: {
      wrapperInner: {
        padding: '8px 8px 8px 24px'
      }
    },
    MuiButton: {
      root: {
        minHeight: '41px',
        fontSize: '16px',
        borderWidth: '10px',
        fontFamily: 'Gotham Medium, sans-serif',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        borderRadius: '0px'
      },
      outlined: {
        padding: '0px 25px'
      },
      contained: {
        padding: '0px 25px'
      },
      label: {},
      sizeSmall: {
        padding: '5px 10px',
        height: '30px'
      },
      containedPrimary: {
        backgroundColor: 'black',
        opacity: '1',
        color: 'white',
        transition: '300ms ease',
        '&:hover': {
          backgroundColor: '#c47200'
        }
      },
      containedSecondary: {
        backgroundColor: '#c47200',
        opacity: '1',
        color: 'white',
        transition: '300ms ease',
        '&:hover': {
          backgroundColor: '#9d5b00'
        }
      }
    },
    MuiSwitch: {
      root: {
        padding: '0',
        borderRadius: '18px',
        width: '53px',
        height: '24px'
      },
      thumb: {
        width: '18px',
        height: '18px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15)'
      },
      switchBase: {
        padding: '3px',
        '&:hover': {
          backgroundColor: 'transparent'
        },
        '&&&&$checked': {
          transform: 'translateX(calc(100% + 5px))',
          color: 'white'
        },
        '&&&&$checked + $track': {
          opacity: '1',
          backgroundColor: 'black'
        },
        '&&&&$checked + &&&&$disabled + $track': {
          opacity: '.2'
        }
      }
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: '50px'
      }
    },
    MuiIconButton: {
      root: {
        padding: '0px'
      }
    },
    MuiMenu: {
      list: {
        backgroundColor: 'black'
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'Gotham Medium, sans-serif',
        textTransform: 'uppercase',
        color: 'white'
      }
    },
    MuiListItem: {
      button: {
        color: '#000000'
      }
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#000000'
        },
        color: '#000000',
        marginBottom: '10px'
      }
    },
    MuiFormControl: {
      root: {
        marginBottom: '.8rem'
      }
    },
    MuiTypography: {
      body1: {
        fontSize: '14px'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '0px',
        '&$focused $notchedOutline': {
          borderColor: '#000000',
          borderWidth: '2px'
        },
        '&:hover $notchedOutline': {
          borderColor: '#000000',
          borderWidth: '2px'
        }
      },
      input: {
        padding: '14px'
      },
      notchedOutline: {
        borderColor: '#000000',
        borderWidth: '2px'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#f5f5f5'
      }
    }
  }
};

const theme = createTheme(themeObj);
export { theme, themeObj };
