import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import CustomLink from '../Link';

const StlWrapper = styled.div`
  width: 100%;
  .list {
    padding: 18px 10px;
    border-top: 1px solid #3e3e3e;
    border-bottom: 1px solid #3e3e3e;
    gap: 12px 25px;
    li {
      a {
        text-transform: uppercase;
        color: gray;
      }
    }
    li.product-underline {
      a {
        color: white;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .list {
      border-top: none;
      padding: 30px 10px;
      gap: 12px 35px;
    }
  }

  @media only screen and (min-width: 1100px) {
    .list {
      padding: 40px 10px 40px;
      li {
        font-size: 25px;
        margin-right: 80px;
      }
    }
  }
`;

const FormsNavigation = ({ className }) => {
  const queryString = window.location.href;
  const ediblesPage = queryString.includes('edibles');
  const flowerPage = queryString.includes('flower');
  const vapesPage = queryString.includes('vapes');
  const concentratesPage = queryString.includes('concentrates');
  const preRollsPage = queryString.includes('pre-rolls');

  return (
    <StlWrapper className={classNames(className)} aria-label="Links to other product pages">
      <ul className="flex-row-center flex-wrap list">
        <li className={flowerPage ? 'product-underline flower-link' : undefined}>
          <CustomLink to="/products/flower">Flower</CustomLink>
        </li>
        <li className={vapesPage ? 'product-underline vapes-link' : undefined}>
          <CustomLink to="/products/vapes">Vapes</CustomLink>
        </li>
        <li className={concentratesPage ? 'product-underline concentrate-link' : undefined}>
          <CustomLink to="/products/concentrates">Concentrates</CustomLink>
        </li>
        <li className={preRollsPage ? 'product-underline prerolls-link' : undefined}>
          <CustomLink to="/products/pre-rolls">Pre-rolls</CustomLink>
        </li>

        <li className={ediblesPage ? 'product-underline edibles-link' : undefined}>
          <CustomLink to="/products/edibles">Edibles</CustomLink>
        </li>
      </ul>
    </StlWrapper>
  );
};

export default FormsNavigation;
