import React from 'react';
import { get } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import renderButtons from '../helpers/renderButtons';

const HeroFullBackgroundImgV2 = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('768'));

  return (
    <section className="full-background-hero-v2">
      <figure className="hero-fig bord-bott-gray ">
        <img
          className="cover-img"
          src={
            isMobile
              ? get(props, 'primary.mobile_image.url')
              : get(props, 'primary.desktop_image.url')
          }
          alt={
            isMobile
              ? get(props, 'primary.mobile_image.alt')
              : get(props, 'primary.desktop_image.alt')
          }></img>
      </figure>
      <div className="bord-bott-gray">
        <div className="copy-wrapper center">
          <PrismicRichText render={get(props, 'primary.copy')} />
          <div className="button-container mt-30">{renderButtons(get(props, 'items'))}</div>
        </div>
      </div>
    </section>
  );
};

export default HeroFullBackgroundImgV2;
