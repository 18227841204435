import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const StateDropdown = ({ value, onChange, style, options, className }) => {
  return (
    <div className="filters flex-center white-select">
      <FormControl>
        <TextField
          color="primary"
          variant="outlined"
          id="select-strain-type"
          className={className}
          select
          name="select-strain"
          label="FILTER BY STATE"
          value={value}
          onChange={onChange}
          style={style}>
          <MenuItem value="All">ALL</MenuItem>
          {options.map((option, i) => (
            <MenuItem key={i} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </div>
  );
};

export default StateDropdown;
