import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, orderBy } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Slices from '../slices';
import { ReactComponent as BackArrow } from '../images/icons/arrow-back.svg';
import { ReactComponent as CircleScribble } from '../images/Scribbles/circle.svg';
import { ReactComponent as WhiteLogo } from '../images/Logo/white-logo-img.svg';
import { ReactComponent as ArrowL } from '../images/icons/arrow-l.svg';
import { ReactComponent as ArrowR } from '../images/icons/arrow-r.svg';
import Link from './../components/Link';
import renderButtons from '../helpers/renderButtons';

const StrainDetailsPage = props => {
  const dispatch = useDispatch();
  const location = useLocation();
  const handleLoading = props.loading;
  const pageLoading = useSelector(state => get(state, 'prismic.strain.loading', true));
  const page = useSelector(state => get(state, 'prismic.strain.data', false));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('900'));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up('1550'));
  const terpenes = get(page, 'terpenes[0]');
  const src = isMobile ? get(page, 'nug_image.Mobile.url') : get(page, 'nug_image.url');
  const alt = isMobile ? get(page, 'nug_image.Mobile.alt') : get(page, 'nug_image.alt');
  const desc = get(page, 'description[0].text');
  const pheno = get(page, 'phenotype[0].text');
  const lineage = get(page, 'lineage[0].text');
  const flavor = get(page, 'potency[0].text');
  const slices = get(page, 'body');
  const hasStrainArt = get(page, 'strain_art_logo_details.url');
  const strains = useSelector(state => get(state, 'prismic.strains_list.data', false));
  const sortedStrains = orderBy(strains, ['data.plant_image.url']);

  const whichPage = path => {
    if (path === '/404') {
      return '404';
    } else {
      return path.split('/')[2];
    }
  };
  const pagePath = whichPage(location.pathname);

  const currentElemPosition = sortedStrains.findIndex(el => el.uid === pagePath);
  const prevElem = get(sortedStrains[currentElemPosition - 1], 'uid');
  const nextElem = get(sortedStrains[currentElemPosition + 1], 'uid');

  const button = [
    {
      active: true,
      cta_link: {
        uid: 'shop'
      },
      cta_text: 'Shop Now',
      button_variant: 'contained',
      button_color: 'secondary'
    }
  ];

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_SINGLE_BY_UID',
      key: 'strain',
      id: pagePath
    });
  }, [dispatch, pagePath]);

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_REPEATABLE',
      key: 'strain',
      reduxKey: 'strains_list'
    });
  }, [dispatch]);

  useEffect(() => {
    if (!pageLoading) {
      handleLoading(false);
    }
  }, [pageLoading, handleLoading]);

  const renderTerpenes = terpenes => {
    return (
      <div className="terpene-list">
        {terpenes.caryophyllene && <li>Caryophyllene</li>}
        {terpenes.humulene && <li>Humulene</li>}
        {terpenes.limonene && <li>Limonene</li>}
        {terpenes.linalool && <li>Linalool</li>}
        {terpenes.myrcene && <li>Myrcene</li>}
        {terpenes.ocimene && <li>Ocimene</li>}
        {terpenes.pinene && <li>Pinene</li>}
        {terpenes.nerolidol && <li>nerolidol</li>}
        {terpenes.terpinoline && <li>terpinoline</li>}
        {terpenes.coming_soon && <li>Coming Soon</li>}
      </div>
    );
  };

  if (!page && !pageLoading) {
    return <Redirect to={'/404'} />;
  }

  return (
    !pageLoading &&
    page && (
      <div className="strain-details-pages">
        <section className="strain-details padding-all bord-bott-gray">
          <div className="strain-wrapper ">
            {isLargeDesktop && prevElem && (
              <Link
                to={`/strains/${prevElem}`}
                className="paginate-btn largeDesktop prev-strain "
                aria-label="previous strain">
                <ArrowL className="arrow arrow-l" style={{ fill: 'white' }} />
                <span className="font-thin">prev strain</span>
              </Link>
            )}
            {isLargeDesktop && nextElem && (
              <Link
                to={`/strains/${nextElem}`}
                className="paginate-btn largeDesktop next-strain"
                aria-label="next strain">
                <span className="font-thin">next strain</span>
                <ArrowR className="arrow arrow-r" style={{ fill: 'white' }} />
              </Link>
            )}

            <Link
              to={`/strain-index`}
              className="back-button font-thin "
              aria-label="back to view all strains">
              <span className="arrow ">
                <BackArrow style={{ width: '18px' }} /> strain index
              </span>
            </Link>

            <div className={!isMobile ? 'strain-desk-grid' : ''}>
              <div className="img-details text-center">
                {hasStrainArt && (
                  <div className="strain-art-wrapper">
                    <figure className="strain-art-img">
                      <img className="contain-img" src={hasStrainArt} alt="" role="presentation" />
                    </figure>
                  </div>
                )}
                {/* <span className="font-thin letter-space-2 block">
                  FIG {get(page, 'figure_number[0].text')}
                </span> */}
                <h1 className="font-normal upper add-scribble">{get(page, 'name[0].text')}</h1>

                {src ? (
                  <img className="contain-img nug-img nug-fig" src={src} alt={alt}></img>
                ) : (
                  <div className="cs-copy cs-details flex-col-center">
                    <WhiteLogo className="cs-details-img" />
                    <p>image coming soon</p>
                  </div>
                )}
              </div>
              <div className="details">
                <div className="desc strain-info-item">
                  {desc ? desc : 'Description coming soon.'}
                </div>
                <div className="pheno strain-info-item">
                  <span className="detail-heading">pheno:</span> {pheno ? pheno : 'Coming Soon'}
                </div>
                <div className="lineage strain-info-item">
                  <span className="detail-heading">lineage:</span>
                  {lineage ? lineage : 'Coming Soon'}
                </div>
                <div className="dominance strain-info-item">
                  <span className="detail-heading">dominance:</span>
                  <span className="circ-scrib-cont relative">
                    <CircleScribble className="the-circ-scrib" />
                    {get(page, 'dominance')}
                  </span>
                </div>
                <div className="potency strain-info-item">
                  <span className="detail-heading">flavor:</span>
                  {flavor ? flavor : 'Coming Soon'}
                </div>
                <ul className="terpenes strain-info-item flex-col-center ">
                  <span className="detail-heading">dominant terpenes:</span>
                  {renderTerpenes(terpenes)}
                </ul>

                {!isMobile && (
                  <div className="strain-info-item shop-now-button">{renderButtons(button)}</div>
                )}
              </div>
            </div>

            {isMobile && (
              <div className="center padding-all-30 strain-cta">{renderButtons(button)}</div>
            )}
          </div>

          {!isLargeDesktop && (
            <div className="paginate-wrapper">
              {prevElem && (
                <Link
                  to={`/strains/${prevElem}`}
                  className="paginate-btn prev-strain"
                  aria-label="previous strain">
                  <ArrowL className="arrow arrow-l" style={{ fill: 'white' }} />
                  <span className="font-thin">prev strain</span>
                </Link>
              )}
              {nextElem && (
                <Link
                  to={`/strains/${nextElem}`}
                  className="paginate-btn next-strain"
                  aria-label="next strain">
                  <span className="font-thin">next strain</span>
                  <ArrowR className="arrow arrow-r" style={{ fill: 'white' }} />
                </Link>
              )}
            </div>
          )}
        </section>
        <Slices slices={slices}></Slices>
      </div>
    )
  );
};

export default StrainDetailsPage;
