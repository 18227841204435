import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { Redirect } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import Slices from '../slices';
import renderButtons from '../helpers/renderButtons';
import { useParams } from 'react-router-dom';
import FormsNavigation from '../components/FormsNavigation';
import styled from 'styled-components';

const StlFormsNavigation = styled(FormsNavigation)`
  padding-top: 120px;
  @media only screen and (min-width: 768px) {
    padding-top: 60px;
  }
`;

const FormPage = props => {
  const dispatch = useDispatch();
  const handleLoading = props.loading;
  const pageLoading = useSelector(state => get(state, 'prismic.form_page.loading', true));
  const page = useSelector(state => get(state, 'prismic.form_page.data', false));
  const { id } = useParams();
  const theme = useTheme();
  const slices = get(page, 'body');

  useEffect(() => {
    if (!pageLoading) {
      handleLoading(false);
    }
  }, [pageLoading, handleLoading]);

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_SINGLE_BY_UID',
      key: 'form_page',
      id
    });
  }, [dispatch, id]);

  if (!page && !pageLoading) {
    return <Redirect to={'/404'} />;
  }

  return (
    !pageLoading &&
    page && (
      <div className="strain-details-pages">
        <StlFormsNavigation />
        <Slices slices={slices}></Slices>
      </div>
    )
  );
};

export default FormPage;
