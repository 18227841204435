import React from 'react';
import { get } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import renderButtons from '../helpers/renderButtons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { ReactComponent as MobileLine } from '../images/svglines/F-Mobile-Line.svg';
import { ReactComponent as DeskLineRight } from '../images/svglines/F-Desk-Right.svg';
import { ReactComponent as DeskLineLeft } from '../images/svglines/F-Desk-Left.svg';

const OppositeContentBlocks = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('768'));

  return (
    <section className="opp-blocks">
      {props.items.map((el, i) => {
        const button = [
          {
            active: get(el, 'active'),
            cta_link: get(el, 'cta_link'),
            cta_text: get(el, 'cta_text'),
            button_variant: get(el, 'button_variant'),
            button_color: get(el, 'button_color')
          }
        ];
        let isEven = i % 2 === 0 ? true : false;

        return (
          <>
            <div key={i} className={`container padding-all center block-${i + 1}`}>
              <LazyLoadImage
                className="cover-img"
                src={isMobile ? get(el, 'image.Mobile.url') : get(el, 'image.url')}
                alt={isMobile ? get(el, 'image.Mobile.alt') : get(el, 'image.alt')}
                effect="opacity"
              />

              <div className="copy-wrapper mx-auto">
                <PrismicRichText render={get(el, 'copy')} />
                {get(el, 'active') && (
                  <div className="button-container mt-30">{renderButtons(button)}</div>
                )}
              </div>
            </div>

            {isMobile && i !== 2 && <MobileLine className="mobile-line animate-line" />}
            {!isMobile && i !== 2 && isEven && (
              <DeskLineRight className={`desktop-line d-right line-r-${i + 1}`} />
            )}
            {!isMobile && i !== 2 && !isEven && (
              <DeskLineLeft className={`desktop-line d-left line-l-${i}`} />
            )}
          </>
        );
      })}
    </section>
  );
};

export default OppositeContentBlocks;
