import React from 'react';
import { get, map } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import Link from './../components/Link';
import { Button } from '@material-ui/core';

const ProductsList = props => {
  const products = () => {
    return map(props.items, (product, i) => (
      <div className="product bord-bott-all black-background" key={i}>
        <figure className="product-img">
          <img
            className="cover-img"
            src={get(product, 'image.url')}
            alt={get(product, 'image.alt')}></img>
        </figure>
        <div className="copy-wrapper center black-background">
          <div className="extra-wrapper">
            <PrismicRichText render={get(product, 'copy')} />
            {get(product, 'button.uid') && (
              <Link to={'/products/' + get(product, 'button.uid')} className="product-link">
                <Button color="secondary" variant="contained" disableElevation>
                  Learn More
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    ));
  };

  return <section className="products-list black-background">{products()}</section>;
};

export default ProductsList;
