import React from 'react';
import { get, map, isEmpty } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import Link from './../components/Link';
import { Button } from '@material-ui/core';

const FormSlice = props => {
  const products = () => {
    return map(props.items, (product, i) => (
      <div className="form-wrapper">
        <div className="form  black-background" key={i}>
          <figure className="form-img bord-bott-all ">
            <img
              className="cover-img"
              src={get(product, 'image.url')}
              alt={get(product, 'image.alt')}></img>
          </figure>
          <div className="copy-wrapper center black-background">
            <div className="extra-wrapper">
              <PrismicRichText render={get(product, 'copy')} />
              {/* START */}
              <div className="info-wrapper">
                {!isEmpty(get(product, 'form[0].text')) && (
                  <div className="description bord-bott-gray ">
                    <p className="description-name upper">FORM:</p>
                    <div className="description-copy">
                      <PrismicRichText render={get(product, 'form')} />
                    </div>
                  </div>
                )}
                {!isEmpty(get(product, 'feelings[0].text')) && (
                  <div className="description bord-bott-gray ">
                    <p className="description-name upper">Feelings:</p>
                    <div className="description-copy">
                      <PrismicRichText render={get(product, 'feelings')} />
                    </div>
                  </div>
                )}
                {!isEmpty(get(product, 'onset_and_duration[0].text')) && (
                  <div className="description bord-bott-gray ">
                    <p className="description-name upper">Onset & Duration:</p>
                    <div className="description-copy">
                      <PrismicRichText render={get(product, 'onset_and_duration')} />
                    </div>
                  </div>
                )}
                {!isEmpty(get(product, 'dosage[0].text')) && (
                  <div className="description upper">
                    <div className="description-copy">
                      <PrismicRichText render={get(product, 'dosage')} />
                    </div>
                  </div>
                )}
              </div>
              {/* END */}
              <Link to="/shop" className="shop-link">
                <Button color="secondary" variant="contained" disableElevation>
                  Shop Now
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return <section className="forms-list black-background">{products()}</section>;
};

export default FormSlice;
