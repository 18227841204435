import * as prismic from '@prismicio/client';

const endpoint = prismic.getRepositoryEndpoint('floracalfarmsstaging');
const accessToken =
  'MC5ZaC1aa2hFQUFDZ0FBc3FV.77-977-9RxQS77-9BTE4Cgnvv707O--_ve-_vX7vv73vv70V77-9OUTvv71E77-977-977-977-9MO-_vS8'; // Set an access token
const routes = [];

const client = prismic.createClient(endpoint, { routes, accessToken });

export default client;
