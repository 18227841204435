import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, last } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import StateDropdown from '../components/StateDropdown';
import StrainInfo from '../components/StrainInfo';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ReactComponent as ArrowL } from '../images/icons/arrow-l.svg';
import { ReactComponent as ArrowR } from '../images/icons/arrow-r.svg';
import PrismicRichText from '../components/PrismicRichText';
import renderButtons from '../helpers/renderButtons';

const StrainFinderCarousel = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('768'));
  const dispatch = useDispatch();
  const [strains, setStrains] = useState(false);
  const [states, setStates] = useState([]);
  const [strainType, setStrainType] = useState('All');
  const loading = useSelector(state => get(state, 'prismic.strains_list.loading', true));
  const data = useSelector(state => get(state, 'prismic.strains_list.data', false));
  const dataStates = useSelector(state => get(state, 'prismic.state_dropdown_list.data', false));
  const statesLoading = useSelector(state =>
    get(state, 'prismic.state_dropdown_list.loading', true)
  );
  const [currentSlide, setCurrentSlide] = useState(0);

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_REPEATABLE',
      key: 'strain',
      reduxKey: 'strains_list'
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_SINGLE_PAGE',
      key: 'state_dropdown_list'
    });
  }, [dispatch]);

  useEffect(() => {
    if (data && !loading) {
      setStrains(data);
    }
  }, [data, loading]);

  useEffect(() => {
    if (dataStates && !statesLoading) {
      const arr = [];
      dataStates.states.map(state => {
        arr.push(get(state, 'state[0].text'));
      });
      setStates(arr);
    }
  }, [dataStates, statesLoading]);

  useEffect(() => {
    if (strains && strainType !== 'All') {
      setStrains(data.filter(el => el.tags.includes(strainType)));
    } else {
      setStrains(data);
    }
  }, [strainType]);

  const handleChange = event => {
    setStrainType(event.target.value);
  };

  if (!strains || !states) {
    return null;
  }

  return (
    strains &&
    states && (
      <div className="padding-all width-700 mx-auto height-100 strain-carousel">
        <div className="copy center width-480">
          <PrismicRichText render={get(props, 'primary.copy')} />
        </div>
        <StateDropdown
          style={{ width: '170px', margin: '30px auto 20px' }}
          options={states}
          value={strainType}
          onChange={handleChange}
        />
        <div className="sfc-wrapper">
          {currentSlide !== 0 && (
            <button
              onClick={prev}
              className="custom-carousel-btn btn-l"
              aria-label="Previous Slide">
              <ArrowL style={{ fill: 'white' }} />
            </button>
          )}
          {currentSlide !== strains.length - 1 && (
            <button onClick={next} className="custom-carousel-btn btn-r" aria-label="Next Slide">
              <ArrowR style={{ fill: 'white' }} />
            </button>
          )}
          <Carousel
            className="strain-carousel"
            showStatus={false}
            showIndicators={true}
            thumbs={true}
            centerMode={true}
            centerSlidePercentage={50}
            selectedItem={currentSlide}>
            {strains.map((strain, i) => (
              <StrainInfo
                key={i}
                to={`/strains/${strain.uid}`}
                figureNum={get(strain, 'data.figure_number[0].text')}
                name={get(strain, 'data.name[0].text')}
                wrapperClassName="squareImg"
                blackExpandBtn
                src={
                  isMobile
                    ? get(strain, 'data.plant_image.Mobile.url')
                    : get(strain, 'data.plant_image.url')
                }
                alt={
                  isMobile
                    ? get(strain, 'data.plant_image.Mobile.alt')
                    : get(strain, 'data.plant_image.alt')
                }
              />
            ))}
          </Carousel>
        </div>

        <div className="button-container mt-30 center">{renderButtons(get(props, 'items'))}</div>
      </div>
    )
  );
};

export default StrainFinderCarousel;
