import { get } from 'lodash';

export default function dialog(state = {}, action) {
  switch (action.type) {
    case 'DIALOG/SHOW':
      return showDialog(state, action);
    case 'DIALOG/CLOSE':
      return { ...state, open: false };
    case 'DIALOG/PRIMARY_DISABLE':
      return { ...state, primaryButtonDisabled: action.disabled };
    case 'DIALOG/PRIMARY_LOADING':
      return { ...state, primaryButtonLoading: action.loading };
    case 'DIALOG/SECONDARY_DISABLE':
      return { ...state, secondaryButtonDisabled: action.disabled };
    case 'DIALOG/SECONDARY_LOADING':
      return { ...state, secondaryButtonLoading: action.loading };
    default:
      return state;
  }
}

function showDialog(state, action) {
  if (action.id) {
    return {
      id: action.id,
      open: true,
      options: action.options,
      data: action.data
    };
  }

  return {
    open: true,
    id: 'generic',
    title: action.title,
    bodyText: action.bodyText,
    bodyNode: action.bodyNode,
    primaryButtonNode: action.primaryButtonNode,
    primaryButtonText: action.primaryButtonText,
    primaryButtonCallback: action.primaryButtonCallback,
    primaryButtonDisabled: action.primaryButtonDisabled,
    closeOnPrimaryClick: get(action, 'closeOnPrimaryClick', true),
    secondaryButtonNode: action.secondaryButtonNode,
    secondaryButtonText: action.secondaryButtonText,
    secondaryButtonCallback: action.secondaryButtonCallback,
    closeOnSecondaryClick: get(action, 'closeOnSecondaryClick', true),
    fullWidth: action.fullWidth,
    closeIcon: action.closeIcon,
    maxWidth: action.maxWidth
  };
}
