import React from 'react';
import { useDispatch } from 'react-redux';

const CookieSettingsLink = () => {
  const dispatch = useDispatch();

  const showCookieSettings = (e) => {
    e.preventDefault();
    dispatch({ type: 'COOKIE_COMPLIANCE/SHOW_DETAIL' });
  };

  return <a href="#" style={{color: "white"}} onClick={showCookieSettings}>Cookie Settings</a>;
};

export default CookieSettingsLink;
