import React from 'react';
import { get } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import renderButtons from '../helpers/renderButtons';

const ContentSlice = props => {
  return (
    <section className="content-slice">
      <div className="container mx-auto padding-all">
        <div className="copy-wrapper">
          <PrismicRichText render={get(props, 'primary.copy')} />
          <div className="button-container mt-30">{renderButtons(get(props, 'items'))}</div>
        </div>
      </div>
    </section>
  );
};

export default ContentSlice;
