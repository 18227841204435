import React, { useState, useEffect } from 'react';
import PrismicRichText from '../components/PrismicRichText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell>
          <Box className="question" margin={1}>
            <PrismicRichText render={row.question} />
          </Box>
        </TableCell>
        <TableCell width={10}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className="answer" margin={1}>
              <PrismicRichText render={row.answer} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const FAQ = slice => {
  const dispatch = useDispatch();
  const loading = useSelector(state => get(state, 'prismic.faqs.loading', true));
  const data = useSelector(state => get(state, 'prismic.faqs.data', false));

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_SINGLE_PAGE',
      key: 'faqs'
    });
  }, [dispatch]);

  if (!loading && !data) {
    return null;
  }
  const backgroundStyle = {
    backgroundColor: slice.primary.background_color,
    color: slice.primary.text_color
  };

  const renderFAQs = items => {
    return items.map((el, i) => <Row key={i} row={el} />);
  };
  return (
    !loading &&
    data && (
      <section className="faq-section padding-all" style={backgroundStyle}>
        <div className="wrapper">
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableBody>{renderFAQs(data.faqs)}</TableBody>
            </Table>
          </TableContainer>
        </div>
      </section>
    )
  );
};

export default FAQ;
