import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { Link } from 'react-router-dom';
import { ReactComponent as WhiteLogo } from '../../images/Logo/white-logo-img.svg';

const StrainInfo = ({
  name,
  blackExpandBtn,
  src,
  alt,
  to,
  wrapperClassName,
  style,
  strainLogo
}) => {
  return (
    <Link to={to} style={style} className="squareContainer">
      <div className="strain-info perfectSquare">
        <div className="squareContent">
          <div className="name bord-bott-gray">
            <h4 className="letter-space-2">{name}</h4>
          </div>
          {src ? (
            <LazyLoadImage
              className="cover-img height-100"
              src={src}
              alt={alt}
              wrapperClassName={wrapperClassName}
              effect="opacity"
            />
          ) : (
            <div className="cs-copy cs-info flex-col-center padding-10">
              <WhiteLogo className="cs-info-img" />
              <p>image coming soon</p>
            </div>
          )}
        </div>
        {blackExpandBtn && (
          <div className="black-plus flex-center font-thin" role="presentation">
            <span>+</span>
          </div>
        )}
      </div>
      {strainLogo && (
        <div className="squareHover">
          <figure className="sqHoverImg">
            <img src={strainLogo} className="contain-img" alt="" role="presentation" />
          </figure>
        </div>
      )}
    </Link>
  );
};

export default StrainInfo;
