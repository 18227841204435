import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, orderBy } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import StateDropdown from '../components/StateDropdown';
import StrainInfo from '../components/StrainInfo';

const StrainFinder = ({}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('768'));
  const dispatch = useDispatch();
  const [strains, setStrains] = useState(false);
  const [states, setStates] = useState([]);
  const [strainType, setStrainType] = useState('All');
  const loading = useSelector(state => get(state, 'prismic.strains_list.loading', true));
  const data = useSelector(state => get(state, 'prismic.strains_list.data', false));
  const dataStates = useSelector(state => get(state, 'prismic.state_dropdown_list.data', false));
  const statesLoading = useSelector(state =>
    get(state, 'prismic.state_dropdown_list.loading', true)
  );

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_REPEATABLE',
      key: 'strain',
      reduxKey: 'strains_list'
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_SINGLE_PAGE',
      key: 'state_dropdown_list'
    });
  }, [dispatch]);

  useEffect(() => {
    if (data && !loading) {
      setStrains(data);
    }
  }, [data, loading]);

  useEffect(() => {
    if (dataStates && !statesLoading) {
      const arr = [];
      dataStates.states.map(state => {
        arr.push(get(state, 'state[0].text'));
      });
      setStates(arr);
    }
  }, [dataStates, statesLoading]);

  useEffect(() => {
    if (strains && strainType !== 'All') {
      setStrains(data.filter(el => el.tags.includes(strainType)));
    } else {
      setStrains(data);
    }
  }, [strainType]);

  const handleChange = event => {
    setStrainType(event.target.value);
  };

  let sortedStrains = orderBy(strains, ['data.plant_image.url']);

  if (!strains || !states) {
    return null;
  }

  return (
    strains &&
    states && (
      <div className="bord-bott bord-bott-gray">
        <StateDropdown
          style={{ width: '170px', marginBottom: '20px' }}
          options={states}
          value={strainType}
          onChange={handleChange}
        />
        <div className="strains resp-grid-4col padding-noTop  ">
          {sortedStrains.map((strain, i) => (
            <StrainInfo
              key={i}
              to={`/strains/${strain.uid}`}
              figureNum={get(strain, 'data.figure_number[0].text')}
              name={get(strain, 'data.name[0].text')}
              wrapperClassName="squareImg"
              blackExpandBtn
              strainLogo={get(strain, 'data.strain_art_logo.url')}
              src={
                isMobile
                  ? get(strain, 'data.plant_image.Mobile.url')
                  : get(strain, 'data.plant_image.url')
              }
              alt={
                isMobile
                  ? get(strain, 'data.plant_image.Mobile.alt')
                  : get(strain, 'data.plant_image.alt')
              }
            />
          ))}
        </div>
      </div>
    )
  );
};

export default StrainFinder;
