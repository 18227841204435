import React from 'react';
import { get } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import renderButtons from '../helpers/renderButtons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Link from './../components/Link';

const ImageGridWithContent = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('768'));

  const backgroundStyle = {
    backgroundColor: get(props, 'primary.background_color'),
    color: get(props, 'primary.text_color')
  };

  const StaticImage = ({ el, itemKey }) => {
    return (
      <div key={itemKey}>
        <h3 className="upper nowrap">{get(el, 'title[0].text')}</h3>
        <LazyLoadImage
          className="cover-img"
          src={isMobile ? get(el, 'image.Mobile.url') : get(el, 'image.url')}
          alt={isMobile ? get(el, 'image.Mobile.alt') : get(el, 'image.alt')}
          effect="opacity"
        />
      </div>
    );
  };

  const ImageLink = ({ el, itemKey, imageUID }) => {
    return (
      <div className="link-hover" key={itemKey}>
        <Link to={`/products/${imageUID}`}>
          <h3 className="upper nowrap">{get(el, 'title[0].text')}</h3>
          <LazyLoadImage
            className="cover-img"
            src={isMobile ? get(el, 'image.Mobile.url') : get(el, 'image.url')}
            alt={isMobile ? get(el, 'image.Mobile.alt') : get(el, 'image.alt')}
            effect="opacity"
          />
        </Link>
      </div>
    );
  };

  return (
    <section className="img-grid-container" style={backgroundStyle}>
      <div className="container padding-noBot mx-auto">
        <div className="copy-wrapper center ">
          <PrismicRichText render={get(props, 'primary.copy')} />
        </div>
      </div>
      <div className="img-grid padding-all">
        {props.items.map((el, i) =>
          get(el, 'image_link.uid') ? (
            <ImageLink itemKey={i} el={el} imageUID={get(el, 'image_link.uid')} />
          ) : (
            <StaticImage itemKey={i} el={el} />
          )
        )}
      </div>
      <div className="button-container mt-30">{renderButtons(get(props, 'items'))}</div>
    </section>
  );
};

export default ImageGridWithContent;
