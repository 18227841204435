import createSegmentScript from './segment';

export function setupScripts() {
  const segmentId = '43BlqWJW5syrtNrq2OSlpth5CIAu6iAf';
  if (window.analyticsInitialized) {
    return;
  }

  window.analyticsInitialized = true;
  setupSegment(segmentId);
}

function setupSegment(segmentKey) {
  var head = document.getElementsByTagName('head')[0];
  var segmentScript = document.createElement('script');
  var inlineScript = document.createTextNode(createSegmentScript(segmentKey));
  segmentScript.appendChild(inlineScript);
  head.appendChild(segmentScript);
}
