import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import linkResolver from './../../helpers/linkResolver';
import AccessibilitySwitch from '../AccessibilitySwitch';
import CookieSettingsLink from './CookieSettingsLink';
import Link from '../Link';
import PrismicRichText from '../PrismicRichText';

export default function Footer() {
  const componentLoading = useSelector(state => get(state, 'prismic.footer.loading', true));
  const component = useSelector(state => get(state, 'prismic.footer.data', false));
  const year = new Date().getFullYear();

  const renderFooterColumns = columns => {
    return columns.map((el, i) => (
      <div key={i} className="footer-section">
        <div className="heading">
          <PrismicRichText linkResolver={linkResolver} render={get(el, 'column_title')} />
        </div>
        <div className="content">
          <PrismicRichText linkResolver={linkResolver} render={get(el, 'content')} />
        </div>
      </div>
    ));
  };

  const renderSocialLinks = socialLinks => {
    return socialLinks.map((el, i) => (
      <Link
        className="social-link"
        key={i}
        url={el.link.url}
        target="_blank"
        rel="noopener noreferrer">
        <img className="social-icon" src={get(el, 'icon.url')} alt="Social Icon"></img>
      </Link>
    ));
  };

  return (
    !componentLoading &&
    component && (
      <footer className="section-padding">
        <div className="wrapper padding-all">
          <div className="footer-section">
            <p>
              <span className="mr-10">Grown by </span>
              <span className="font-bold cresco">CRESCO LABS &#x00AE;</span>
            </p>
            <p>We’re on a mission to normalize, professionalize, and revolutionize cannabis.</p>
          </div>
          {renderFooterColumns(component.footer)}
          <div className="footer-section">
            <div className="cookie-settings mt-10">
              <CookieSettingsLink />
            </div>
            <div className="social">
              <span className="copyright">
                <p> © {year} FloraCal All rights reserved.</p>
                <p>LIC # CCL18-0002186</p>
              </span>
              <div className="social-icons">
                {renderSocialLinks(component.social_links)}
                <div className="accessibility ml-25">
                  <AccessibilitySwitch />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  );
}
