import React from 'react';
import { get } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import renderButtons from '../helpers/renderButtons';
import ImgCopyCta from '../components/ImgCopyCta';

const StandardHero = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('768'));
  const border = get(props, 'primary.bottom_border');
  const altHeadingCopy = get(props, 'primary.alternate_heading_copy') ? 'altHeadingCopy' : null;

  const backgroundStyle = {
    backgroundColor: get(props, 'primary.background_color'),
    color: get(props, 'primary.text_color')
  };

  const src = isMobile ? get(props, 'primary.image.Mobile.url') : get(props, 'primary.image.url');

  const alt = isMobile ? get(props, 'primary.image.Mobile.alt') : get(props, 'primary.image.alt');

  return (
    <ImgCopyCta
      sectionStyle={backgroundStyle}
      sectionClasses={`standard-hero ${border ? 'bord-bott-gray' : ''} `}
      wrapperClasses="container padding-all"
      image
      imageClasses="cover-img"
      src={src}
      alt={alt}
      copyClasses={`copywrapper m-30-auto center width-1100 ${altHeadingCopy}`}
      copyText={get(props, 'primary.copy')}
      buttons={renderButtons(get(props, 'items'))}
      buttonClasses="button-container mt-30"
    />
  );
};

export default StandardHero;
