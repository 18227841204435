import React from 'react';
import HeroFullBackgroundImg from './HeroFullBackgroundImg';
import ImageContent from './ImageContent';
import StandardHero from './StandardHero';
import OppositeContentBlocks from './OppositeContentBlocks';
import StrainFinder from './StrainFinder';
import StrainFinderCarousel from './StrainFinderCarousel';
import ThreeImagesWithContent from './3ImagesWithContent';
import FAQ from './FAQ';
import ContentSlice from './ContentSlice';
import HeroFullBackgroundImgV2 from './HeroFullBackgroundImgV2';
import ProductsList from './ProductsList';
import FormSlice from './FormSlice';
import ImageGridWithContent from './ImageGridWithContent';
import HeadlineCopyHero from './HeadlineCopyHero';

export default function Slices(props) {
  if (!document) {
    return null;
  }

  return (
    <div className={`slice-wrapper`}>
      {props.slices.map((slice, index) => (
        <div
          key={index}
          className={`slice-single ${
            slice.primary.section_width === 'Half Width' ? 'desk_half_w' : 'desk_full_w'
          } ${slice.primary.background_color === 'black' ? 'black_back' : ''}`}>
          {slice.slice_type === 'hero_full_background_image' && HeroFullBackgroundImg(slice)}
          {slice.slice_type === 'image_content' && ImageContent(slice)}
          {slice.slice_type === 'standard_hero' && StandardHero(slice)}
          {slice.slice_type === 'opposite_content_blocks' && OppositeContentBlocks(slice)}
          {slice.slice_type === 'strain_finder' && StrainFinder(slice)}
          {slice.slice_type === 'strain_finder_carousel' && StrainFinderCarousel(slice)}
          {slice.slice_type === 'faq' && FAQ(slice)}
          {slice.slice_type === '3_images_with_content' && ThreeImagesWithContent(slice)}
          {slice.slice_type === 'content_slice' && ContentSlice(slice)}
          {slice.slice_type === 'full_background_hero_v2' && HeroFullBackgroundImgV2(slice)}
          {slice.slice_type === 'products_list' && ProductsList(slice)}
          {slice.slice_type === 'form_slice' && FormSlice(slice)}
          {slice.slice_type === 'image_grid_with_content' && ImageGridWithContent(slice)}
          {slice.slice_type === 'headline_copy_hero' && HeadlineCopyHero(slice)}
        </div>
      ))}
    </div>
  );
}
