import React from 'react';
import { get } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import renderButtons from '../helpers/renderButtons';

const HeroFullBackgroundImg = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('768'));

  const backgroundStyle = {
    backgroundColor: get(props, 'primary.background_color'),
    color: get(props, 'primary.text_color')
  };

  return (
    <section className="full-background-hero" style={backgroundStyle}>
      <figure className="hero-fig">
        <img
          className="cover-img"
          src={isMobile ? get(props, 'primary.image.Mobile.url') : get(props, 'primary.image.url')}
          alt={
            isMobile ? get(props, 'primary.image.Mobile.alt') : get(props, 'primary.image.alt')
          }></img>
      </figure>
      <div className="container">
        <div className="copy-wrapper top">
          <PrismicRichText render={get(props, 'primary.content')} />
          <div className="button-container mt-30">{renderButtons(get(props, 'items'))}</div>
        </div>
      </div>
    </section>
  );
};

export default HeroFullBackgroundImg;
