import React from 'react';
import { get } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import renderButtons from '../helpers/renderButtons';
import ImgCopyCta from '../components/ImgCopyCta';

const ImageContent = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('768'));
  const src = isMobile ? get(props, 'primary.image.Mobile.url') : get(props, 'primary.image.url');
  const alt = isMobile ? get(props, 'primary.image.Mobile.alt') : get(props, 'primary.image.alt');

  return (
    <ImgCopyCta
      sectionClasses="img-content"
      lazyImage
      wrapperClasses="container padding-all center"
      wrapperClassName="lazy-img-wrapper"
      imageClasses="cover-img"
      src={src}
      alt={alt}
      copyClasses="copywrapper mx-auto center"
      separateTitle={get(props, 'primary.title')}
      copyText={get(props, 'primary.copy')}
      buttons={renderButtons(get(props, 'items'))}
      buttonClasses="button-container mt-30"
    />
  );
};

export default ImageContent;
