import React from 'react';
import PrismicPage from './pages/PrismicPage';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import StoreLocatorPage from './pages/StoreLocatorPage';
import ShopPage from './pages/ShopPage';
import StrainDetailsPage from './pages/StrainDetailsPage';
import FormPage from './pages/FormPage';

export default function Routes({ loading, isStaging }) {
  const { pathname } = useLocation();
  const prismicPage = () => {
    return <PrismicPage loading={loading} isStaging={isStaging} />;
  };

  const shopPage = () => {
    return <ShopPage loading={loading} />;
  };

  const strainDetailPage = () => {
    return <StrainDetailsPage loading={loading} />;
  };

  const storeLocatorPage = () => {
    return <StoreLocatorPage loading={loading} />;
  };

  const formPage = () => {
    return <FormPage loading={loading} />;
  };

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route exact path="/contact">
        <Redirect to="/404" />
      </Route>
      <Route path="/strains/:id" render={strainDetailPage} />
      <Route path="/shop" render={shopPage} />
      <Route exact path="/store-locator" render={storeLocatorPage} />
      <Route path="/products/:id" render={formPage} />
      <Route path="/:id" render={prismicPage} />
      <Route exact path="/" render={prismicPage} />
    </Switch>
  );
}
