import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import PrismicRichText from '../PrismicRichText';
import { isEmpty } from 'lodash';

const ImgCopyCta = ({
  sectionClasses,
  sectionStyle,
  wrapperClasses,
  wrapperClassName,
  lazyImage,
  image,
  alt,
  src,
  imageClasses,
  copyClasses,
  copyText,
  separateTitle,
  buttons,
  buttonClasses
}) => {
  return (
    <section className={sectionClasses} style={sectionStyle}>
      <div className={wrapperClasses}>
        {!isEmpty(separateTitle) && (
          <div className="sep-title">
            <PrismicRichText render={separateTitle} />
          </div>
        )}
        {lazyImage && (
          <LazyLoadImage
            className={imageClasses}
            wrapperClassName={wrapperClassName}
            src={src}
            alt={alt}
            effect="opacity"
          />
        )}
        {image && (
          <figure>
            <img className={imageClasses} src={src} alt={alt}></img>
          </figure>
        )}
        {copyText && (
          <div className={copyClasses}>
            <PrismicRichText render={copyText} />
            {buttons && <div className={buttonClasses}>{buttons}</div>}
          </div>
        )}
      </div>
    </section>
  );
};

export default ImgCopyCta;
