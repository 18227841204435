import React from 'react';
import { get } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import renderButtons from '../helpers/renderButtons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const ThreeImagesWithContent = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('768'));

  const backgroundStyle = {
    backgroundColor: get(props, 'primary.background_color'),
    color: get(props, 'primary.text_color')
  };

  return (
    <section className="three-img-section" style={backgroundStyle}>
      <div className="container padding-noBot mx-auto">
        <div className="copy-wrapper center ">
          <PrismicRichText render={get(props, 'primary.copy')} />
        </div>
      </div>
      <div className="three-col-grid padding-all">
        {props.items.map((el, i) => (
          <div key={i}>
            <h3 className="upper">{get(el, 'title[0].text')}</h3>
            <LazyLoadImage
              className="cover-img"
              src={isMobile ? get(el, 'image.Mobile.url') : get(el, 'image.url')}
              alt={isMobile ? get(el, 'image.Mobile.alt') : get(el, 'image.alt')}
              effect="opacity"
            />
          </div>
        ))}
      </div>
      <div className="button-container mt-30">{renderButtons(get(props, 'items'))}</div>
    </section>
  );
};

export default ThreeImagesWithContent;
