import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import Cookie from 'js-cookie';
import { Button } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Link } from 'react-router-dom';

const AgeGateContent = ({ verifyAge }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [verified, setVerified] = useState(false);
  const [spanish, toggleSpanish] = useState(false);

  const pageLoading = useSelector(state => get(state, 'prismic.age_gate.loading', true));
  const page = useSelector(state => get(state, 'prismic.age_gate.data', false));

  const logo = get(page, 'age_gate[0].logo.url');

  const copy = {
    english: {
      headline: 'Are You Over 21?',
      body: (
        <>
          Yes, I am at least twenty-one (21) years of age or am otherwise a qualified patient. By
          clicking “I agree” and entering the website, I agree to be bound by the{' '}
          <Link to="/terms-of-service">Terms of Service</Link> and{' '}
          <Link to="/privacy-policy">Privacy Policy</Link>.
        </>
      ),
      buttonYes: 'Yes',
      buttonNo: 'No'
    },
    spanish: {
      headline: '¿Es mayor de 21 años?',
      body: (
        <>
          Sí, tengo al menos veintiún (21) años o soy un paciente calificado. Al hacer clic en
          “Acepto” y entrar en el sitio web, acepto los{' '}
          <Link to="/terms-of-service">Términos de servicio</Link> y la{' '}
          <Link to="/privacy-policy">Política de privacidad</Link>.
        </>
      ),
      buttonYes: 'Sí',
      buttonNo: 'No'
    }
  };

  const localizedCopy = spanish ? copy.spanish : copy.english;

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_SINGLE_PAGE',
      key: 'age_gate'
    });
  }, [dispatch]);

  const isOver21 = () => {
    Cookie.set('florcal_over21', true, { expires: 3 });
    setVerified(true);
    verifyAge();
  };

  const notOver21 = () => {
    setError('You must be 21 years old or a qualified medical patient to enter the site.');
  };

  const handleSwitch = () => {
    toggleSpanish(prev => !prev);
  };

  return (
    !pageLoading &&
    !verified && (
      <div id="age-gate">
        <div className="spanish-toggle">
          <FormControlLabel
            control={
              <Switch checked={spanish} onChange={handleSwitch} color="primary" className="mr-10" />
            }
            label={!spanish ? 'ES' : 'EN'}
          />
        </div>
        <div className="age-gate-content flex-row-center">
          <div>
            <div className="agegate-logo">
              {logo && <img alt="" role="presentation" src={logo} />}
            </div>
            <div className="content-section text-center">
              <h1 id="modal-title" className="font-bold no-line-break line-space">
                {localizedCopy.headline}
              </h1>
              <div className="ada-reorder">
                <p className="fine-print">{localizedCopy.body}</p>
                <div id="age-gate-form">
                  <Button
                    type="submit"
                    onClick={isOver21}
                    color="primary"
                    variant="contained"
                    disableElevation>
                    {localizedCopy.buttonYes}
                  </Button>
                  <Button
                    type="submit"
                    onClick={notOver21}
                    color="primary"
                    variant="contained"
                    disableElevation>
                    {localizedCopy.buttonNo}
                  </Button>
                  {error && <p className="error-message">{error}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const AgeGate = ({ verifyAge }) => {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={true}
      fullScreen={true}
      keepMounted
      PaperProps={{ 'aria-labelledby': 'modal-title' }}>
      <AgeGateContent verifyAge={verifyAge} />
    </Dialog>
  );
};

export default AgeGate;
